import { DataService } from "services/data.service"
import { Component, OnInit } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore"
import { Observable } from "rxjs"
import { Profile } from "interfaces/account.interface"
import { tap } from "rxjs/operators"
import { AuthService } from "services/auth.service"
import firebase from "firebase"
import Timestamp = firebase.firestore.Timestamp

@Component({
  template: `
    <ng-container *ngIf="auth.profile$ | async">
      <ng-container *ngIf="profiles$ | async as profiles">
        <div
          style="display: flex; justify-content: center"
          class="flex-wrap"
        >
          <div
            *ngFor="let profile of profiles"
            class="padding-1em center"
          >
            <div style="outline: solid 1px black;">
              <div style="text-align: center;">
                <a href="mailto:{{profile.id}}">
                  {{ profile.id }}
                </a>
              </div>
              <div style="text-align: center;">
                Last Login:
                {{ profile.dateUpdatedString }}
              </div>
              <div
                class="flex-wrap"
                style="display: flex;"
              >
                <div class="padding-1em">
                  <div *ngFor="let role of rolesList">
                    <ng-container *ngIf="profile.roles[role]">
                      <button
                        mat-raised-button
                        color="warn"
                        style="margin: .25em; width: 100px;"
                        (click)="setRole(profile.id, profile.roles, role, false)"
                      >
                        {{ role }}
                      </button>
                    </ng-container>
                    <ng-container *ngIf="!profile.roles[role]">
                      <button
                        mat-raised-button
                        color="primary"
                        style="margin: .25em; width: 100px;"
                        (click)="setRole(profile.id, profile.roles, role, true)"
                      >
                        {{ role }}
                      </button>
                    </ng-container>
                  </div>


                  <input
                    type="number"
                    [value]="profile.memberships.annual || 0"
                    (change)="runningEdit($event, profile)"
                  >
                </div>
                <div class="padding-1em">
                  <div
                    class="profile-image padding-1em"
                    style="padding-bottom: 0;"
                  >
                    <img
                      [src]="profile.user.photoURL"
                      alt="user photo"
                    >
                  </div>
                  <div
                    class="center"
                    style="width: 150px;"
                  >
                    {{ profile.user.displayName }}
                  </div>
                </div>
              </div>
              {{ profile.id }}
              <app-orders
                [ordersTitle]=""
                [uid]="profile.uid"
                [context]="'user-admin'"
              ></app-orders>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
  selector: "app-admin-users",
})
export class AdminUsersComponent implements OnInit {
  private profilesCollection: AngularFirestoreCollection<Profile>
  profiles$: Observable<Profile[]>
  rolesList = ["admin", "editor", "subscriber"]

  constructor(
    private afs: AngularFirestore,
    public auth: AuthService,
    private dataService: DataService,
  ) {
  }

  ngOnInit() {
    this.profilesCollection = this.afs
      .collection("profiles", ref =>
        ref
          .where("dateUpdatedTimestamp", ">=", Timestamp.now().toMillis() - 60 * 60 * 24 * 30 * 6 * 1000)
          .orderBy("dateUpdatedTimestamp", "desc"))

    this.profiles$ = this.profilesCollection
      .valueChanges()
      .pipe(
        tap(profiles => {
          profiles.map(profile => {
            profile.user.displayName = profile.user.displayName
              .replace("@", " ")
          })
          if (profiles) {
            profiles
              .sort((a, b) => {
                if (a["dateUpdatedTimestamp"] > b["dateUpdatedTimestamp"]) {
                  return -1
                }
                if (a["dateUpdatedTimestamp"] < b["dateUpdatedTimestamp"]) {
                  return 1
                }
                return 0
              })
          }
        })
      )

    /**
     * used one time to copy users docs into customers collection
     * preserving as sample code in case we need to make a self-healing customers method
     */
    /*
        this.afs.collection('users').snapshotChanges().subscribe(users => {
          users.forEach(snap => {
            const user = snap.payload.doc.data();
            const id = snap.payload.doc.id;
            this.afs.collection('customers').doc(id).set(user, {merge: true}).then().catch();
          });
        });
    */
  }

  setRole(profileId, profileRoles, role, value) {
    const profileRef: AngularFirestoreDocument<any> = this.afs.collection("profiles").doc(profileId)
    console.log("setRole")
    profileRef
      .set({ roles: { [role]: value } }, { merge: true })
      .then()
      .catch(() => {
        console.error("setRole error")
      })
  }

  runningEdit(event, profile) {
    if (event.target.valueAsNumber !== undefined) {
      const value = event.target.valueAsNumber
      console.log(this.auth.user.email)
      console.log(value)
      if (value >= 2012 && value <= 2040 && this.auth.user.email === "lowell@digitalfarrier.com") {
        console.log("runningEdit()")
        this.dataService
          .insertData("profiles",
            profile.id,
            { memberships: { annual: value } }
          )
          .then()
          .catch(() => {
            console.error("runningEdit() error")
          })
      }
    }
  }

}

import { Component, Input, OnInit } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { AuthService } from "services/auth.service"
import { CartService } from "services/cart.service"
import { LineItem } from "interfaces/cart.interface"
import { DataService } from "services/data.service"

@Component({
  styles: [`
    .full-width {
      width: 100%;
    }
    
    .row {
      display: flex;
      flex-direction: row;
    }

    .col {
      flex: 1;
      margin-right: 20px;
    }

    .col:last-child {
      margin-right: 0;
    }
  `],
  template: `
    <form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()">
      <mat-card style="min-width: 120px; margin: 20px auto;">
        <mat-card-header>
          <mat-card-title>Shipping Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <!--
                <div class="row">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="Company" formControlName="company">
                    </mat-form-field>
                  </div>
                </div>
          -->
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Full name"
                  formControlName="name"
                  autocomplete="name"
                >
                <mat-error *ngIf="name.invalid && name.touched" class="has-text-danger">
                  <!--
                              <mat-error *ngIf="addressForm.controls['name'].hasError('required')">
                  -->
                  Full name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <textarea
                  matInput
                  placeholder="Address"
                  formControlName="address"
                  autocomplete="address"
                ></textarea>
                <mat-error *ngIf="address.invalid && address.touched" class="has-text-danger">
                  <!--
                              <mat-error *ngIf="addressForm.controls['address'].hasError('required')">
                  -->
                  Address is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="!hasUnitNumber">
            <div class="col">
              <button mat-button type="button" (click)="hasUnitNumber = !hasUnitNumber">
                + Add C/O, Apt, Suite, Unit
              </button>
            </div>
          </div>
          <div class="row" *ngIf="hasUnitNumber">
            <div class="col">
              <mat-form-field class="full-width">
                <textarea
                  matInput
                  placeholder="Address 2"
                  formControlName="address2"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="City"
                  formControlName="city"
                  autocomplete="address-level2"
                >
                <mat-error *ngIf="city.invalid && city.touched" class="has-text-danger">
                  <!--
                              <mat-error *ngIf="addressForm.controls['city'].hasError('required')">
                  -->
                  City is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="full-width">
                <mat-select
                  placeholder="State"
                  formControlName="state"
                >
                  <mat-option
                    *ngFor="let state of states"
                    [value]="state.abbreviation"
                  >
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="state.invalid && state.touched" class="has-text-danger">
                  <!--
                              <mat-error *ngIf="addressForm.controls['state'].hasError('required')">
                  -->
                  State is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  formControlName="postalCode"
                  placeholder="Postal Code"
                  autocomplete="postal-code"
                >
              </mat-form-field>
              <mat-error *ngIf="postalCode.invalid && postalCode.touched" class="has-text-danger">
                Zip Code is <strong>required</strong>
              </mat-error>
            </div>
          </div>
          <!--
                <div class="row">
                  <div class="col">
                    <mat-radio-group formControlName="shipping">
                      <mat-radio-button value="free">Free Shipping</mat-radio-button>
                      <mat-radio-button value="priority">Priority Shipping</mat-radio-button>
                      <mat-radio-button value="nextday">Next Day Shipping</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
          -->
        </mat-card-content>
        <!--
            <mat-card-actions>
              <button mat-raised-button color="primary" type="submit">Submit</button>
            </mat-card-actions>
        -->
      </mat-card>
    </form>
  `,
  selector: "app-shipping-address",
})
export class ShippingAddressComponent implements OnInit {
  @Input() lineItems: LineItem[]
  addressForm: FormGroup
  salesTaxLineItem: LineItem
  shippingLineItem: LineItem
  hasUnitNumber = false

  states = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "American Samoa", abbreviation: "AS" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "District Of Columbia", abbreviation: "DC" },
    { name: "Federated States Of Micronesia", abbreviation: "FM" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Guam", abbreviation: "GU" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Marshall Islands", abbreviation: "MH" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Northern Mariana Islands", abbreviation: "MP" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Palau", abbreviation: "PW" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Puerto Rico", abbreviation: "PR" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virgin Islands", abbreviation: "VI" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" }
  ]

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private cartService: CartService,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.salesTaxLineItem = this.lineItems.filter(e => e.type === "sales tax")[0]
    this.shippingLineItem = this.lineItems.filter(e => e.type === "shipping")[0]
    this.cartService.shippingState = false
    this.addressForm = this.fb.group({
      // company: null,
      name: ["", [
        Validators.required
      ]],
      address: ["", [
        Validators.required
      ]],
      address2: "",
      city: ["", [
        Validators.required
      ]],
      state: ["", [
        Validators.required
      ]],
      postalCode: ["", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(10),
        Validators.pattern("^[0-9]{5}(?:-[0-9]{4})?$")
      ]],
      // shipping: ['free', Validators.required]
    })

    const address = this.addressForm.getRawValue()
    if (!address.name) {
      this.cartService.shippingAddressValues.name = this.auth.account.user.displayName
    }

    this.addressForm.patchValue(this.cartService.shippingAddressValues)

    if (this.cartService.shippingAddressValues.state === "WI") {
      this.cartService.shippingState = true
    }

    this.cartService.shippingAddress = false
    this.addressForm.statusChanges.subscribe(status => {
      if (status === "INVALID") {
        this.cartService.shippingAddress = false
      }
      if (status === "VALID") {
        this.cartService.shippingAddress = true
      }
    })
    this.addressForm.valueChanges.subscribe(value => {
      const previousAddress = this.cartService.shippingAddressValues
      this.cartService.shippingAddressValues = {
        name: value.name,
        address: value.address,
        address2: value.address2,
        city: value.city,
        state: value.state,
        postalCode: value.postalCode
      }
      if (previousAddress.state !== "WI" && value.state === "WI") {
        this.cartService.shippingState = true
      } else if (previousAddress.state === "WI" && value.state !== "WI") {
        this.cartService.shippingState = false
      }
      if (previousAddress.state === "WI" || value.state === "WI") {
        this.salesTaxLineItem.price = 1
        console.log("salesTaxLineItem")
        this.dataService
          .insertData("cart-line-items", this.salesTaxLineItem.id, this.salesTaxLineItem)
          .then()
          .catch(() => {
            console.error("Error inserting sales tax line item")
          })
      }
    })
  }

  get name() {
    return this.addressForm.get("name")
  }

  get address() {
    return this.addressForm.get("address")
  }

  get address2() {
    return this.addressForm.get("address2")
  }

  get city() {
    return this.addressForm.get("city")
  }

  get state() {
    return this.addressForm.get("state")
  }

  get postalCode() {
    return this.addressForm.get("postalCode")
  }


  onSubmit() {
    // alert('Thanks!');
  }
}

import { Component, Input, OnInit } from "@angular/core"
import { Observable } from "rxjs"
import { LineItem, Order } from "interfaces/cart.interface"
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore"
import { AuthService } from "services/auth.service"
import firebase from "firebase/app"
import "firebase/firestore"
import { map } from "rxjs/operators"

@Component({
  template: `
    <h2>
      {{ ordersTitle }}
    </h2>
    <ng-container *ngIf="orderLineItems$ | async as orderLineItems">
      <div
        *ngFor="let orderLineItem of orderLineItems"
        style="padding: .5rem;"
      >
        <!--    Order Date: {{order.dateUpdatedTimestamp | date}}-->
        <app-order-line-item [lineItem]="orderLineItem" [context]="context"></app-order-line-item>
      </div>
    </ng-container>
  `,
  selector: "app-orders",
})
export class OrdersComponent implements OnInit {
  @Input() uid: string
  @Input() ordersTitle: string
  @Input() context: string
  orderLineItems$: Observable<LineItem[]>
  orderLineItemsRef: AngularFirestoreCollection<LineItem>

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) {
  }

  ngOnInit() {
    if (!this.uid && this.auth.account) {
      this.uid = this.auth.account.uid
      this.ordersTitle = "My Order History"
    }
    this.orderLineItemsRef = this.afs
      .collection("order-line-items", ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
          query = query.where("uid", "==", this.uid)
          return query
        }
      )
    this.orderLineItems$ = this.orderLineItemsRef
      .valueChanges()
      .pipe(
        map(orderLineItems => {
          return orderLineItems
            .filter((orderLineItem) =>
              orderLineItem.type !== "sales tax" && orderLineItem.type !== "shipping"
            )
        })
      )
  }
}

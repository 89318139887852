import { ImagesService } from "services/images.service"
import { Observable, combineLatest } from "rxjs"
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore"
import { Component } from "@angular/core"
import { Order, LineItem } from "interfaces/cart.interface"
import { map } from "rxjs/operators"
import { AuthService } from "services/auth.service"
import firebase from "firebase"
import Timestamp = firebase.firestore.Timestamp

@Component({
  template: `
    <ng-container *ngIf="profile$ | async as profile">
      <h3>
        Order History
      </h3>
      <ng-container *ngIf="profile.roles.admin && ordersAndLineItems$ | async as ordersAndItems">
        <div
          *ngFor="let order of ordersAndItems['orders']"
          style="border: 1px solid gray"
          fxLayout="column"
          fxLayoutAlign="start space-evenly"
        >
          <p>
            Purchase Status: {{ order.status }}
          </p>
          <p>
            Amount: {{ (order.amount / 100) | currency }}
          </p>
          <p>
            Purchase Date: {{ order['dateUpdatedString'] | date }}
          </p>

          <p>
            UserEmail: {{ order['userEmail'] }}
          </p>
          <ng-container *ngFor="let lineItem of ordersAndItems['lineItems']">
            <div>
              <div *ngIf="order['lineItems']?.includes(lineItem.id)">
                <img
                  [src]="getUrl(lineItem.image['filepath'], 50)"
                  style="width: 50px;"
                  [alt]="lineItem.image.filename"
                >
                {{ lineItem.image.filename }}
                {{ lineItem.type }}:
                <strong>
                  {{ lineItem.option }}
                </strong>
              </div>
            </div>
          </ng-container>
        </div>

      </ng-container>
    </ng-container>
  `,
  selector: "app-admin-orders",
})
export class AdminOrdersComponent {
  profile$ = this.authService.profile$

  ordersRef: AngularFirestoreCollection<Order> = this.afs
    .collection("orders", ref =>
      ref
        .where("dateUpdatedTimestamp", ">=", Timestamp.now().toMillis() - 60 * 60 * 24 * 30 * 18 * 1000)
        .orderBy("dateUpdatedTimestamp", "desc"))
  orders$: Observable<Order[]> = this.ordersRef.valueChanges()

  lineItemsRef: AngularFirestoreCollection<LineItem> = this.afs
    .collection("order-line-items")
  lineItems$: Observable<LineItem[]> = this.lineItemsRef.valueChanges()

  ordersAndLineItems$: Observable<any> = combineLatest([this.orders$, this.lineItems$])
    .pipe(
      map(([orders, lineItems]) => {
        return { orders, lineItems }
      })
    )

  constructor(
    private afs: AngularFirestore,
    private imagesService: ImagesService,
    private authService: AuthService
  ) {}

  getUrl(path: string, size: number) {
    return this.imagesService.getUrl(path, size)
  }

}

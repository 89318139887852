import { Component, OnInit } from "@angular/core"
import { CartService } from "services/cart.service"
import { Observable } from "rxjs"
import { CheckoutType, LineItem } from "interfaces/cart.interface"
import { tap } from "rxjs/operators"
import { DataService } from "services/data.service"
import { AuthService } from "services/auth.service"
import firebase from "firebase"
import Timestamp = firebase.firestore.Timestamp

@Component({
  template: `
    <div style="display: block; margin: 20px auto; max-width: 600px;">
      <h1>
        Shopping Cart
      </h1>
      <ng-container *ngIf="cartLineItems$ | async as lineItems">
        <ng-container *ngFor="let lineItem of lineItems">
          <app-cart-line-item [cartLineItem]="lineItem" [cartLineItems]="lineItems"></app-cart-line-item>
        </ng-container>
        <app-shipping-address
          *ngIf="cartService.shippableCartLineItems?.length" [lineItems]="lineItems"
        ></app-shipping-address>
        <app-checkout
          [cartLineItems]="lineItems"
          [amount]="cartTotal"
          [description]="'Cart Order'"
          [actionMessage]="'Place Order'"
          [type]="CheckoutType.cart"
        ></app-checkout>
      </ng-container>
    </div>
  `,
  selector: "app-cart",
})
export class CartComponent implements OnInit {
  cartLineItems$: Observable<LineItem[]>
  cartTotal: number
  CheckoutType = CheckoutType

  constructor(
    public cartService: CartService,
    private dataService: DataService,
    private auth: AuthService,
  ) {
  }

  ngOnInit() {
    this.cartLineItems$ = this.cartService.cartLineItems$
      .pipe(
        tap(cartLineItems => {
          // console.log('cartLineItems')
          // console.log(cartLineItems)
          this.cartTotal = cartLineItems.reduce((total, cartLineItem) => {
            return total + (cartLineItem.quantity * cartLineItem.price)
          }, 0)
          const salesTaxLineItems: LineItem[] = cartLineItems
            .filter(e => e.type === "sales tax")
          const newCartAndShippingLineItemDateUpdated = this.auth.getDate()
          if (!salesTaxLineItems.length) {
            const newDocId = this.dataService.getNewDocId()
            const newSalesTaxLineItem: LineItem = {
              dateUpdated: Timestamp.now(),
              dateUpdatedString: newCartAndShippingLineItemDateUpdated.isoDateTimeHourMin,
              dateUpdatedTimestamp: newCartAndShippingLineItemDateUpdated.timeStamp,
              id: newDocId,
              image: this.cartService.salesTaxImage,
              option: "Sales Tax",
              price: 0,
              quantity: 1,
              sessionId: this.cartService.sessionId,
              singular: true,
              status: "pending",
              type: "sales tax",
              uid: this.auth.account.uid,
            }
            console.log("newSalesTaxLineItem")
            this.dataService
              .insertData("cart-line-items", newSalesTaxLineItem.id, newSalesTaxLineItem)
              .then()
              .catch(() => {
                console.error("error")
              })
          }
          const shippingLineItems: LineItem[] = cartLineItems
            .filter(e => e.type === "shipping")
          if (!shippingLineItems.length) {
            const newDocId = this.dataService.getNewDocId()
            const newShippingLineItem: LineItem = {
              dateUpdatedString: newCartAndShippingLineItemDateUpdated.isoDateTimeHourMin,
              dateUpdatedTimestamp: newCartAndShippingLineItemDateUpdated.timeStamp,
              id: newDocId,
              image: this.cartService.shippingImage,
              option: "Shipping",
              price: 0,
              quantity: 1,
              sessionId: this.cartService.sessionId,
              singular: true,
              status: "pending",
              type: "shipping",
              uid: this.auth.account.uid,
            }
            console.log("newShippingLineItem")
            this.dataService
              .insertData("cart-line-items", newShippingLineItem.id, newShippingLineItem)
              .then()
              .catch(() => {
                console.error("error")
              })
          }


        })
      )
  }

}

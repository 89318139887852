import { Injectable } from "@angular/core"
import { DataService } from "services/data.service"
import { AuthService } from "services/auth.service"
import { AngularFirestore } from "@angular/fire/firestore"

@Injectable({
  providedIn: "root"
})
export class FavoritesService {

  constructor(
    private auth: AuthService,
    private dataService: DataService,
    private afs: AngularFirestore,
  ) {
  }

  toggleFavorite(userEmail: string, favorites: {}, filepath: string, addFavorite: boolean) {
    const fileParts = filepath.split("/")
    const filename = fileParts[fileParts.length - 1]
    if (addFavorite) {
      favorites[filename] = {
        filepath: filepath
      }
    } else {
      delete favorites[filename]
    }
    console.log("toggleFavorite")
    this.afs
      .collection("favorites")
      .doc(userEmail)
      .set(favorites)
      .then()
      .catch(() => {
        console.error("Error saving to Firestore")
      })

    this.auth.sendGA("Favorites");

/*
    (<any>window).ga("send", {
      hitType: "event",
      eventCategory: "Favorites",
      eventAction: addFavorite,
      eventLabel: filepath
    })
*/
  }

}

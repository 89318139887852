import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import firebase from "firebase/app"
import "firebase/auth"
import { AngularFireAuth } from "@angular/fire/auth"
import { AngularFireFunctions } from "@angular/fire/functions"
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore"
import { ConfirmationsService } from "services/confirmations.service"
import { Account, Memberships, Modes, Profile, Roles, User } from "interfaces/account.interface"
import { Date } from "interfaces/date.interface"
import { BehaviorSubject, Observable, of, Subscription } from "rxjs"
import { first, shareReplay, switchMap, tap } from "rxjs/operators"
import UserCredential = firebase.auth.UserCredential
import Timestamp = firebase.firestore.Timestamp

@Injectable()
export class AuthService {
  accountRef: AngularFirestoreDocument
  profileRef: AngularFirestoreDocument<Profile>
  userAccountRef: AngularFirestoreDocument
  account$: Observable<any>
  favorites$: Observable<any>
  favoritesCount: number
  profile$: Observable<Profile>
  membershipYear: BehaviorSubject<number>
  membershipYearValue: number
  accountSub: Subscription
  profileSub: Subscription
  account: Account
  modes: Modes
  roles: Roles
  user: User
  memberships: Memberships
  membershipLoading: boolean
  checkoutPending: boolean
  cartStatus: string

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private functions: AngularFireFunctions,
    private confirmationService: ConfirmationsService,
  ) {
    this.membershipYear = new BehaviorSubject<number>(0)
    this.loadEmptyProfile()
    //// Get auth data, then get firestore account document || null
    this.account$ = this.afAuth.authState
      .pipe(
        switchMap(authAccount => {
          if (authAccount) {
            this.accountRef = this.afs
              .collection("accounts")
              .doc(authAccount.uid)
            return this.accountRef.valueChanges()
          } else {
            return of(null)
          }
        })
      )

    this.accountSub = this.account$
      .pipe(
        tap(account => {
          if (account) {
            this.profileRef = this.afs
              .collection("profiles")
              .doc(account.user.email)
            this.profile$ = this.profileRef
              .valueChanges()
              .pipe(
                shareReplay(),
                tap(profile => {
                  // console.log(profile)
                  if (profile) {
                    profile.user.displayName = profile.user.displayName.replace("@", " ")
                    this.modes = profile.modes
                    this.roles = profile.roles
                    this.user = profile.user
                    this.memberships = profile.memberships
                  }
                })
              )

            this.user = account.user
            this.account = account
            this.favorites$ = this.afs
              .collection("favorites")
              .doc(account.user.email)
              .valueChanges()
              .pipe(
                shareReplay(),
                tap(favorites => {
                  if (favorites) {
                    let count = null
                    Object.entries(favorites).forEach(([filename]) => {
                      count = Object.entries(favorites).length
                      // temporary, until all favorites docs are purged of filename: field
                      // console.log("favorites-temporary")
                      /*
                                            this.afs
                                              .collection("favorites")
                                              .doc(account.user.email)
                                              .set({ [filename]: { filename: firebase.firestore.FieldValue.delete() } }, { merge: true })
                                              .then()
                                              .catch()
                      */
                      // temporary, until all favorites docs are purged of filename: field
                    })
                    if (count) {
                      this.favoritesCount = count
                    }
                  } else {
                    // console.log("favorites")
                    this.afs
                      .collection("favorites")
                      .doc(account.user.email)
                      .set({}, { merge: true })
                      .then()
                      .catch()
                  }
                })
              )
          }
        })
      )
      .subscribe()
    this.membershipYear.subscribe(year => {
      this.membershipYearValue = year
    })

    /*
        this.teamEmails().forEach(email => {
          email = email.toLowerCase();
          this.afs
            .collection('legacy-users')
            .doc(email)
            .set({memberships: { annual: '2019'}}, {merge: true})
            .then()
            .catch();
        });
    */

  }

  async getUser() {
    return this.afAuth.authState.pipe(first()).toPromise()
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider()
    return this.oAuthLogin(provider)
  }

  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider()
    return this.oAuthLogin(provider)
  }

  /*
    facebookLogin() {
      const provider = new auth.FacebookAuthProvider();
      return this.oAuthLogin(provider);
    }
  */

  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider()
    return this.oAuthLogin(provider)
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then(userCredential => {
        this.updateUserData(userCredential)
      })
      .catch(error => {
        if (error.code === "auth/account-exists-with-different-credential") {
          console.log("gotcha")
          console.log(error)
          // } else {
          // console.log('error');
          // console.error(error);
        }
      })
  }

  getDate(): Date {
    const date = new Date()
    const isoDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, -1)
    const isoDateTimeHourMin = isoDate.slice(0, -7)
    const timeStamp = date.valueOf()
    return { date, isoDate, isoDateTimeHourMin, timeStamp }
  }

  private updateUserData(userCredential: UserCredential) {
    const user = userCredential.user
    const userEmail = user.email || user.uid

    // Send account data to firestore on login
    const accountData: Account = {
      accountType: userCredential.additionalUserInfo.providerId || "",
      user: {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      },
      uid: user.uid,
    }
    this.userAccountRef = this.afs
      .collection("accounts")
      .doc(user.uid)
    console.log("accountData", accountData)
    this.userAccountRef
      .set(accountData, { merge: true })
      .then()
      .catch()


    const profileDateUpdated = this.getDate()

    const profileData: Profile = {
      accounts: {
        [user.uid]: true,
      },
      dateUpdated: Timestamp.fromMillis(0),
      dateUpdatedString: profileDateUpdated.isoDateTimeHourMin,
      dateUpdatedTimestamp: profileDateUpdated.timeStamp,
      id: user.email,
      memberships: {
        annual: 0
      },
      modes: {},
      roles: {
        authenticated: true
      },
      uid: user.uid,
      user: {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      },
    }

    console.log("user.email", user.email)
    console.log("profileData", profileData)

    this.afs
      .collection("profiles")
      .doc(user.email)
      .set(profileData, { merge: true })
      .then()
      .catch(() => {
        console.error("profiles")
      })


    // remove the following mergeLegacyData modifications when finished migrating legacy data
    this.profileRef = this.afs
      .collection("profiles")
      .doc(user.email)
    this.profileSub = this.profileRef
      .valueChanges()
      .pipe(
        tap(profile => {
          if (profile) {
            this.modes = profile.modes
            this.roles = profile.roles
            this.user = profile.user
            this.memberships = profile.memberships
          }
        })
      )
      .subscribe(profile => {
        if (profile) {
          this.membershipYear.next(profile.memberships.annual)
          profileData.memberships.annual = profile.memberships.annual
          // this.mergeLegacyData(profileData)
          // this.profileSub.unsubscribe();
          this.syncStripeData(user.uid, profile.dateUpdated)
        } else {
          // this.mergeLegacyData(profileData)
          // this.profileSub.unsubscribe();
          this.syncStripeData(user.uid)
        }
        this.sendGA("Login")
        // this.profileSub.unsubscribe();
      })
  }

  private syncStripeData(uid, dateUpdated?: Timestamp) {
    const customerSubscription = this.afs
      .collection("customers")
      .doc(uid)
      .valueChanges()
      .subscribe(customer => {
        // console.log("customer", customer)
        this.getConfirmations(uid, dateUpdated)
          .then(() => {
            customerSubscription.unsubscribe()
          })
          .catch(() => {
            console.error("customers")
            customerSubscription.unsubscribe()
          })
        customerSubscription.unsubscribe()
      })
  }

  private async getConfirmations(uid, dateUpdated: Timestamp) {
    const fun = this.functions.httpsCallable("stripeGetConfirmations")
    try {
      const confirmations = await fun({ uid: uid }).toPromise()
      if (confirmations) {
        confirmations.data.forEach(confirmation => {
          // if (!dateUpdated || Timestamp.fromMillis(confirmation.created * 1000) > dateUpdated) {
            this.confirmationService.addRecord(confirmation)
          // }
        })
      }
/*
      if (!confirmations) {
        this.afs
          .collection("profiles")
          .doc(this.account.user.email)
          .set({}, { merge: true })
          .then()
          .catch()
      }
*/
    }
    catch {
      console.error("stripeGetConfirmations")
/*
      this.afs
        .collection("profiles")
        .doc(this.account.user.email)
        .set({}, { merge: true })
        .then()
        .catch()
*/
    }
  }

  private mergeLegacyData(profileData) {
    const legacyRef: AngularFirestoreDocument = this.afs.collection("legacy-users").doc(profileData.id)
    const legacySub: Subscription = legacyRef
      .snapshotChanges()
      .subscribe(snap => {
        if (snap.payload.exists) {
          const data = snap.payload.data()
          if (data.memberships !== undefined && data.memberships.annual !== undefined) {
            if (parseInt(data.memberships.annual, 10) > profileData.memberships.annual) {
              profileData.memberships.annual = parseInt(data.memberships.annual, 10)
              console.log("mergeLegacyData-profileData-subscription")
              this.profileRef
                .set(profileData, { merge: true })
                .then()
                .catch(() => {
                  console.error("profiles")
                })
            }
            delete data.memberships
            console.log("mergeLegacyData-profileData-legacy")
            legacyRef
              .set(data)
              .then()
              .catch(() => {
                console.error("legacy-users")
              })
            legacySub.unsubscribe()
          }
          legacySub.unsubscribe()
        } else {
          legacySub.unsubscribe()
        }
      })
    this.profileSub.unsubscribe()
    // console.log("mergeLegacyData-profileData-init")
    this.profileRef
      .set(profileData, { merge: true })
      .then()
      .catch(() => {
        console.error("profiles")
      })
  }

  signOut() {
    // this.sendGA('Logout');
    this.loadEmptyProfile()
    this.afAuth.signOut()
      .then(() => {
        this.router
          .navigate(["/"])
          .then()
          .catch(() => {
            console.error("signOut")
          })
        this.account$ = of(null)
        // this.profile$ = of(null);
        this.account = null
      })
      .catch(() => {
        console.error("signOut")
      })
  }

  ///// Role-based Authorization //////
  /*
    canView(roles: Roles): boolean {
      const allowed = [''];
      return this.checkAuthorization(roles, allowed);
    }
  */

  /*
    canRead(roles: Roles): boolean {
      const allowed = [''];
      return this.checkAuthorization(roles, allowed);
    }
  */

  canEdit(roles: Roles): boolean {
    const allowed = ["superAdmin", "admin", "editor"]
    return this.checkAuthorization(roles, allowed)
  }

  /*
    canDelete(roles: Roles): boolean {
      const allowed = ['superAdmin', 'admin'];
      return this.checkAuthorization(roles, allowed);
    }
  */

  /*
    isAuthenticated(roles: Roles): boolean {
      const allowed = ['authenticated'];
      return this.checkAuthorization(roles, allowed);
    }
  */

  /*
    isSuperAdmin(roles: Roles): boolean {
      const allowed = ['superAdmin'];
      return this.checkAuthorization(roles, allowed);
    }
  */

  canAdminUsers(roles: Roles): boolean {
    const allowed = ["admin", "accountAdmin", "superAdmin"]
    return this.checkAuthorization(roles, allowed)
  }

  // determines if account has matching role
  private checkAuthorization(roles: Roles, allowedRoles: string[]): boolean {
    if (!roles.authenticated) {
      return false
    }
    for (const role of allowedRoles) {
      if (roles[role]) {
        return true
      }
    }
    return false
  }

  sendGA(eventCategory) {
    /*
        let eventLabel = "anonymous"
        if (this.roles && this.roles.authenticated && !this.roles.superAdmin) {
          eventLabel = this.user.displayName
        }
        const date = new Date()
        const isoDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, -1)
        const isoDateTimeHourMin = isoDate.slice(0, -7)
        if (!this.roles || !this.roles.authenticated || !this.roles.superAdmin) {
          (<any>window).ga("send", {
            hitType: "event",
            eventCategory: eventCategory,
            eventAction: isoDateTimeHourMin,
            eventLabel: eventLabel
          })
        }
    */
  }

  loadEmptyProfile() {
    this.roles = {
      authenticated: false
    }
    this.user = {
      email: "",
      displayName: "",
      photoURL: ""
    }
    this.modes = {}
    this.memberships = {
      annual: 0
    }
    this.profile$ = new BehaviorSubject<Profile>(
      {
        accounts: {},
        dateUpdated: Timestamp.fromMillis(0),
        dateUpdatedString: "",
        dateUpdatedTimestamp: 0,
        id: "",
        memberships: this.memberships,
        modes: this.modes,
        roles: this.roles,
        uid: "",
        user: this.user
      }
    ).asObservable()
    this.favorites$ = new BehaviorSubject<any>(
      {}
    ).asObservable()
  }

  /*
    private teamEmails() {
      return [
        'maheenott@gmail.com'
      ];
    }
  */

}

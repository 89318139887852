import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { Folder } from "interfaces/folder.interface"
import { Image } from "interfaces/image.interface"

@Injectable({
  providedIn: "root"
})
export class GalleryService {
  items: Folder[]
  title: string
  leaf: string
  nodes: string[]
  newTag: string
  tags: string[]
  images: Image[]

  // maybe doesn't belong here
  selections: Object[][]

  scrollIndex: number
  sizer: number
  public: boolean
  imageInRoute: boolean
  routeImageIndex: BehaviorSubject<number | null>

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    this.images = []
    this.nodes = []
    this.items = []
    this.tags = []
    this.routeImageIndex = new BehaviorSubject(null)
  }

  private getRoute() {
    const route = this.router.url.split("?")[0]
    return route
      .replace("/", "")
      .replace(/-/g, " ")
  }

  getRouteParts() {
    return this.getRoute().split("/")
  }

  setSearchRoute() {
    this.router
      .navigate([], {
        queryParams: {
          tag: this.newTag
        },
        queryParamsHandling: "merge",
      })
      .then()
      .catch(() => {
        console.error("Error setting search route")
      })
  }

  updateSearch() {
    this.setSearchRoute()
    if (this.newTag === "" || this.newTag === undefined) {
      this.tags = []
    } else {
      this.tags[0] = this.newTag
    }
    this.buildImageArray(this.items, "docs")
  }

  resetSearch() {
    this.newTag = ""
    this.removeTag(0)
    // this.buildImageArray('docs');
  }

  removeTag(tagIndex: number) {
    const newTags: string[] = []
    this.tags.forEach(function (t: string, i: number, _) {
      if (tagIndex !== i) {
        newTags.push(t)
      }
    })
    this.tags = newTags
    this.buildImageArray(this.items, "docs")
  }

  buildImageArray(items, type) {
    this.images = []
    if (type === "docs") {
      // TODO: this.items is from last selected folder. Change this to use the parent folder instead.
      items.map(doc => {
        if (doc.id) {
          // check doc against aggregated nodes and selected leaf:
          if ((doc.folderPath !== this.leaf) && !this.nodes.some(node => doc.folderPath.startsWith(node))) {
            return
          }

          Object.entries(doc["imageMap"]).forEach(([filename, properties]) => {
            const filePath = doc.folderPath + "/" + filename
            let match = false
            // let tags = '';

            let tags = []
            if (properties["tags"] !== undefined) {
              tags = properties["tags"]
            }

            if (properties["tags"]) {
              // tags = ' (' + properties['tags'].join(' ') + ')';

              match = this.tags
                .map(tag => this.zeroify(tag))
                .some(tag => properties["tags"].includes(tag))
            }

            if (tags[tags.length - 1] !== "") {
              tags.push("")
            }

            if (match || !this.tags.length) {
              this.images.push(
                {
                  "filename": filename,
                  "filepath": filePath,
                  "tags": tags,
                },
              )
            }
          })
          this.images = this.images.sort((a, b) => {
            if (a.filename < b.filename) {
              return -1
            }
            if (a.filename > b.filename) {
              return 1
            }
            return 0
          })
        }
      })
    } else if (type === "favorites") {
      Object.entries(items).forEach(([filename, properties]) => {
        let tags = []
        if (properties["tags"] !== undefined) {
          tags = properties["tags"]
        }
        this.images.push(
          {
            "filename": filename,
            "filepath": properties["filepath"],
            "tags": tags,
          },
        )
      })
    }
  }

  urlImageMatch() {
    const route = this.getRoute()
    this.images.forEach((image, index) => {
      if (route === image.filepath) {
        this.routeImageIndex.next(index)
      }
    })
  }

  addMoreImages(n) {
    this.scrollIndex = this.scrollIndex + n
  }

  getUrl(path) {
    const imagePath: string = "public-800/" + path
    return "https://storage.googleapis.com/photos-public.xtrphoto.com/" +
      imagePath
        .split("/").join("%2F")
        .split(" ").join("%20")
  }

  setRoute(imagePath) {
    const cleanPath = imagePath.replace(/\s/g, "-")
    this.router
      .navigate([cleanPath], {
        queryParams: {
          tag: this.newTag
        },
        queryParamsHandling: "merge",
      })
      .then()
      .catch(() => {
        console.error("Error setting route")
      })
  }

  loaded(event, index) {
    if (event.img.height && event.img.width && this.images[index] !== undefined) {
      const element = document.getElementById(event.filepath)
      const size = this.sizer || 200
      // element.setAttribute("height", "200px")
      // element.setAttribute("width", "200px")
      element.setAttribute("style", "background-image: url(\"" + event.img.src + "\"); height: " + size + "px; width: " + size + "px;")
      // this.images[index]["height"] = event.img.height
      // this.images[index]["width"] = event.img.width
    }
  }

  private zeroify(tag: string) {
    while (tag.length < 4) {
      tag = "0" + tag
    }
    return tag
  }

  isFolderSelected(folder: Object) {
    return this.selections.some(selections => {
      return selections.some(selection => selection === folder)
    })
  }

  selectFolder(folder: Folder) {
    this.public = folder.public
    this.setSelectionLevel(folder.folderMap.length)
    this.selections[folder.folderMap.length] = []
    this.selections[folder.folderMap.length][0] = folder
    if (Object.keys(folder.imageMap).length) {
      // if folder has images
      this.leaf = folder.folderPath
      this.buildImageArray([folder], "docs")
    } else {
      this.leaf = null
    }
  }

  setSelectionLevel(level: number) {
    this.selections = this.selections.slice(0, level)
  }

  clearSelections() {
    this.selections = []
    this.selections[0] = [0]
  }

  folderLabel(folder) {
    if (folder.label) {
      return folder.label
    } else {
      return folder.folderMap["level" + (folder.folderMap.length - 1)]
    }
  }

}

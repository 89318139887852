import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { ClientActionData } from "interfaces/actions.interface"

@Injectable()
export class ActionsService {
  constructor(
    private afs: AngularFirestore
  ) {
  }

  saveActionData(clientActionData: ClientActionData) {
    switch (clientActionData.action) {
      case "deleteDoc":
        this.saveToFirestore((clientActionData))
        break
      case "insertData":
        switch (clientActionData.collection) {
          case "profiles":
            this.saveToFirestore((clientActionData))
        }
    }
  }

  private saveToFirestore(clientActionData: ClientActionData) {
    this.afs
      .collection("actions")
      .doc(this.afs.createId())
      .set(clientActionData)
      .then(r => {
      })
      .catch(() => {
        console.error("Error saving to Firestore")
      })
  }

}

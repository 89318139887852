import { Injectable } from "@angular/core"
import { AuthService } from "services/auth.service"
import { LogService } from "services/log.service"
import { Cart, CartOption, LineItem } from "interfaces/cart.interface"
import { Image } from "interfaces/image.interface"
import { DataService } from "services/data.service"
import { Observable, Subscription } from "rxjs"
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore"
import { tap } from "rxjs/operators"
import firebase from "firebase/app"
import "firebase/firestore"
import { CartOptionsService } from "services/cart-options.service"

@Injectable({
  providedIn: "root",
})
export class CartService {
  cartId: string
  sessionId: string
  shippable: number
  shippableCartLineItems: LineItem[]
  shippingAddress = false
  shippingFee = {
    smallPrint: 300,
    largePrint: 600
  }
  shippingImage: Image
  shippingState = false
  salesTaxable: number
  salesTaxableCartLineItems: LineItem[]
  salesTaxAmount = 0
  salesTaxPercent = 5.5
  salesTaxImage: Image
  shippingAddressValues = {
    name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    postalCode: ""
  }

  cartLineItems: LineItem[]
  cartLineItemsInit$: Observable<LineItem[]>
  cartLineItemsInitSubscription: Subscription
  cartLineItems$: Observable<LineItem[]>
  cartLineItemsRef: AngularFirestoreCollection<LineItem>
  cartCheckoutDisabled: boolean
  cartOptions$: Observable<CartOption[]>

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private dataService: DataService,
    private ls: LogService,
    private cartOptionsService: CartOptionsService,
  ) {
    this.cartOptions$ = this.cartOptionsService.cartOptions$
    this.initializeCartLineItems()
    this.salesTaxImage = {
      filename: "x-sales-tax-image.png",
      filepath: "x-sales-tax-image.png",
      tags: []
    }
    this.shippingImage = {
      filename: "y-shipping-image.png",
      filepath: "y-shipping-image.png",
      tags: []
    }

    this.cartId = this.auth.account?.uid
    // console.log(this.auth.user)
    // console.log(this.auth.account)

    if (this.cartId) {
      this.cartLineItemsRef = this.afs
        .collection("cart-line-items", ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
          query = query.where("uid", "==", this.cartId)
          return query
        })

      this.cartLineItemsInit$ = this.cartLineItemsRef
        .valueChanges()
      this.cartLineItemsInitSubscription = this.cartLineItemsInit$
        .subscribe(cartLineItems => {
          if (cartLineItems) {
            this.sessionId = this.dataService.getNewDocId()
            cartLineItems.forEach(cartLineItem => {
              cartLineItem.sessionId = this.sessionId
              // console.log("cartLineItem")
              this.dataService
                .insertData("cart-line-items", cartLineItem.id, cartLineItem)
                .then()
                .catch(() => {
                  console.error("cart-line-items")
                })
            })
            this.cartLineItemsInitSubscription
              .unsubscribe()
          }
        })

      this.cartLineItems$ = this.cartLineItemsRef
        .valueChanges()
        .pipe(
          tap(cartLineItems => {
            cartLineItems
              .sort((a, b) => {
                if (a["option"] < b["option"]) {
                  return -1
                }
                if (a["option"] > b["option"]) {
                  return 1
                }
                return 0
              })
              .sort((a, b) => {
                if (a["image"]["filepath"] < b["image"]["filepath"]) {
                  return -1
                }
                if (a["image"]["filepath"] > b["image"]["filepath"]) {
                  return 1
                }
                return 0
              })

            this.cartCheckoutDisabled = false
            cartLineItems.forEach(cartLineItem => {
              // console.log(this.sessionId)
              // console.log(cartLineItem.sessionId)
              if (cartLineItem.sessionId !== this.sessionId) {
                this.cartCheckoutDisabled = true
              }
            })
            if (this.cartCheckoutDisabled) {
              return
            }

            this.shippableCartLineItems = cartLineItems
              .filter(e => e.quantity && (e.type === "small print" || e.type === "large print" || e.type === "collage"))
            this.shippable = this.shippableCartLineItems.length
            const shippingLineItems = cartLineItems
              .filter(e => e.type === "shipping")
            const shippingLineItem = shippingLineItems[0]
            if (shippingLineItems.length > 1) {
              this.removeLineItem("", shippingLineItems[1])
            }
            let shippingAmount = 0
            const smallPrint = this.shippableCartLineItems
              .filter(e => e.type === "small print")
            const largePrint = this.shippableCartLineItems
              .filter(e => e.type === "large print" || e.type === "collage")
            if (largePrint.length) {
              shippingAmount = this.shippingFee.largePrint
            } else if (smallPrint.length) {
              shippingAmount = this.shippingFee.smallPrint
            } else {
              shippingAmount = 0
            }
            if (!this.shippable) {
              shippingAmount = 0
            }
            if (shippingLineItem && shippingAmount !== shippingLineItem.price) {
              shippingLineItem.price = shippingAmount
              // console.log("shippingLineItem")
              this.dataService
                .insertData("cart-line-items", shippingLineItem.id, shippingLineItem)
                .then()
                .catch(() => {
                  console.error("cart-line-items")
                })
            }

            this.salesTaxableCartLineItems = cartLineItems
              .filter(e => e.quantity && (e.type === "small print" || e.type === "large print" || e.type === "collage"))
            this.salesTaxable = this.salesTaxableCartLineItems.length
            const salesTaxLineItems = cartLineItems
              .filter(e => e.type === "sales tax")
            const salesTaxLineItem = salesTaxLineItems[0]
            if (salesTaxLineItems.length > 1) {
              this.removeLineItem("", salesTaxLineItems[1])
            }
            const taxableAmount = this.salesTaxableCartLineItems.reduce((total, item) => {
              return total + (item.quantity * item.price)
            }, 0)
            let salesTaxAmount = Math.round(this.salesTaxPercent * taxableAmount / 100)
            if (!this.shippable || !this.shippingState) {
              salesTaxAmount = 0
            }
            if (salesTaxLineItem && salesTaxAmount !== salesTaxLineItem.price) {
              salesTaxLineItem.price = Math.round(salesTaxAmount)
              // console.log("salesTaxLineItem")
              this.dataService
                .insertData("cart-line-items", salesTaxLineItem.id, salesTaxLineItem)
                .then()
                .catch(() => {
                  console.error("cart-line-items")
                })
            }
          })
        )
    }
  }

  getAllCarts(sort: "asc" | "desc" = "asc"): Observable<Cart[]> {
    const collectionRef: AngularFirestoreCollection<Cart> = this.afs
      .collection("carts", ref => {
        return ref
          .orderBy("dateUpdatedTimestamp", sort)
      })
    return collectionRef.valueChanges()
  }

  getLineItem(lineItemId: string): Observable<LineItem> {
    const lineItemRef: AngularFirestoreDocument<LineItem> = this.afs.collection("cart-line-items").doc(lineItemId)
    return lineItemRef.valueChanges()
  }

  lineItemQuantityChange(cartLineItem: LineItem): void {
    // console.log('lineItemQuantityChange:cartLineItem')
    // console.log(cartLineItem)
    if (cartLineItem.singular && cartLineItem.quantity > 1) {
      cartLineItem.quantity = 1
    } else {
      // console.log("cartLineItem")
      this.dataService
        .insertData("cart-line-items", cartLineItem.id, cartLineItem)
        .then()
        .catch(() => {
          console.error("cart-line-items")
        })
    }
  }

  addLineItem(ngModelCartLineItem: LineItem, cartLineItem: LineItem, cartOptions: CartOption[], cartLineItems: LineItem[]): void {
    // console.log('ngModelCartLineItem')
    // console.log(ngModelCartLineItem)
    // console.log('cartLineItem')
    // console.log(cartLineItem)
    // console.log('  selectedCartOption')
    // console.log(selectedCartOption)
    // console.log('cartLineItemsExistingDigital')
    // console.log(cartLineItemsExistingDigital)
    // console.log('cartLineItemsExistingNonDigital')
    // console.log(cartLineItemsExistingNonDigital)
    const selectedCartOption = cartOptions
      .filter(e => e.option === ngModelCartLineItem.option)[0]
    const cartLineItemsExistingDigital: LineItem[] = cartLineItems
      .filter(e => e.type === "digital" && e.image.filename === cartLineItem.image.filename)
    const cartLineItemsExistingNonDigital: LineItem[] = cartLineItems
      .filter(e => e.type !== "digital" && e.option === ngModelCartLineItem.option && e.image.filename === cartLineItem.image.filename)

    if (selectedCartOption.type === "digital" && cartLineItemsExistingDigital.length) {
      if (ngModelCartLineItem.option === cartLineItemsExistingDigital[0].option) {
        this.log("Toggle digital price to refresh ngModel selection.")
        cartLineItemsExistingDigital[0].price = 0
        this.insertLineItem(cartLineItemsExistingDigital[0].id, cartLineItemsExistingDigital[0])
      }
      this.log("Update existing digital cartLineItem with new ngModel selection.")
      cartLineItemsExistingDigital[0].option = selectedCartOption.option
      cartLineItemsExistingDigital[0].price = selectedCartOption.price
      this.insertLineItem(cartLineItemsExistingDigital[0].id, cartLineItemsExistingDigital[0])
    } else if (selectedCartOption.type !== "digital" && cartLineItemsExistingNonDigital.length) {
      this.log("Update existing non-digital cartLineItem quantity.")
      cartLineItemsExistingNonDigital[0].quantity++
      this.insertLineItem(cartLineItemsExistingNonDigital[0].id, cartLineItemsExistingNonDigital[0])
    } else {
      this.log("Make new cartLineItem in collection based on ngModel selection.")
      const newDocId = this.dataService.getNewDocId()
      const newCartLineItem: LineItem = {
        dateUpdatedString: "",
        dateUpdatedTimestamp: 0,
        id: newDocId,
        image: cartLineItem.image,
        option: selectedCartOption.option,
        price: selectedCartOption.price,
        quantity: 1,
        sessionId: this.sessionId,
        singular: selectedCartOption.singular,
        status: "pending",
        type: selectedCartOption.type,
        uid: cartLineItem.uid
      }
      this.insertLineItem(newDocId, newCartLineItem)
    }
  }

  insertLineItem(docId, doc) {
    const cartLineItemDocDateUpdated = this.auth.getDate()
    doc.dateUpdatedString = cartLineItemDocDateUpdated.isoDateTimeHourMin
    doc.dateUpdatedTimestamp = cartLineItemDocDateUpdated.timeStamp

    // console.log("insertLineItem")
    this.dataService
      .insertData("cart-line-items", docId, doc)
      .then()
      .catch(() => {
        console.error("cart-line-items")
      })
  }

  removeLineItem(event, lineItem: LineItem) {
    // this.log('', 'cart.service.removeLineItem lineItem')
    // this.log('table', lineItem)
    if (event) {
      event.preventDefault()
    }
    if (this.auth.cartStatus === "processing") {
      return
    }

    this.afs.collection("cart-line-items").doc(lineItem.id)
      .delete()
      .then()
      .catch(() => {
        console.error("cart-line-items")
      })

    // const batch = this.afs.firestore.batch()
    // batch.delete(
    //   this.afs.firestore
    //     .collection('cart-line-items')
    //     .doc(lineItem.id)
    // )
    // const date = this.auth.getDate()
    // batch.update(
    //   this.afs.firestore
    //     .collection('carts')
    //     .doc(lineItem.uid),
    //   {
    //     dateUpdatedString: date.isoDateTimeHourMin,
    //     dateUpdatedTimestamp: date.timeStamp,
    //     lineItems: firebase.firestore.FieldValue.arrayRemove(lineItem.id)
    //   }
    // )
    // batch
    //   .commit()
    //   .then()
    //   .catch()
  }

  getNewDocId(): string {
    return this.dataService.getNewDocId()
  }

  private log(message) {
    // console.log(message)
    // const enabled = true
    // const enabled = false
    // if (enabled) {
    //   if (type === 'constructor') {
    //     this.ls.log(type, 'cart.service.' + message)
    //   } else {
    //     this.ls.log(type, 'cart.service.' + message)
    //   }
    // }
  }

  private initializeCartLineItems() {

  }

}

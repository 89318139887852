import { Image, ImageSizeAndFormat } from "interfaces/image.interface"
import firebase from "firebase"
import Timestamp = firebase.firestore.Timestamp

export interface Cart {
  dateUpdatedString?: string
  dateUpdatedTimestamp?: number
  id: string
  orderStatus?: string
  uid: string
  userEmail: string
}

export interface Order {
  amount: number
  cartLineItems?: LineItem[]
  dateUpdated?: Timestamp
  dateUpdatedString: string
  dateUpdatedTimestamp: number
  delivered?: boolean
  id: string
  status: "pending" | "processing" | "cancelled" | "completed"
  uid: string
  userEmail: string
  userDisplayName: string
  shippingName: string,
  shippingAddress: string,
  shippingAddress2: string,
  shippingCityStateZip: string,
}

export enum CheckoutType {
  cart = "cart",
  membership = "membership",
  sync = "sync",
}

export interface LineItem {
  id: string
  dateUpdated?: Timestamp
  dateUpdatedString: string
  dateUpdatedTimestamp: number
  image: Image | null
  orderId?: string
  option: LineItemOption
  price: number
  quantity: number
  sessionId: string
  singular: boolean
  status: "pending" | "processing" | "cancelled" | "completed"
  type: ItemType
  uid: string
}

export type ItemType = "membership" | "digital" | "small print" | "large print" | "collage" | "sales tax" | "shipping"

export interface CartOption {
  disabled?: boolean
  dollars: string
  option: ImageSizeAndFormat
  price: number
  singular: boolean
  type: ItemType
}

export type LineItemOption = ImageSizeAndFormat | "Membership" | "Sales Tax" | "Shipping"
